import React, { useEffect, useMemo, useState } from "react";
import PartySizeControl from "../../components/PartySizeControl";
import styled from "styled-components";
import UserInformation from "../../components/UserInformation";
import { useDispatch, useSelector } from "react-redux";
import {
  setCustomerCallingCode,
  setCustomerDetails,
  setPartySize,
  skipPartySize,
  skipPartySizeAndRemember,
  unSkipPartySize,
  unSkipPartySizeAndRemember,
} from "redux/createOrder";
import { useDefaultSetting } from "hooks/useDefaultSetting";
import { ResturantService } from "services/ResturantService";
import { toggleConfig } from "redux/app";
import { toast } from "react-toastify";
import { ImSpinner2 } from "react-icons/im";
import { formatPhoneNumber } from "../../../../../../utillity/formatHelpers";

const PartySizeLayout = () => {
  const dispatch = useDispatch();
  const [customerDetail, setCustomerDetail] = useState({
    customer_name: "",
    customer_number: "",
    customer_calling_code: "",
  });
  const { skip_customer_info } = useDefaultSetting();
  const [loading, setLoading] = useState(false);
  const { partySize } = useSelector((state) => state.createOrder);
  const [makeDefault, setMakeDefault] = useState(false);

  useEffect(() => {
    setMakeDefault(skip_customer_info);
  }, [skip_customer_info]);

  // Control input function
  const handlePartySize = (e) => {
    let { value } = e.target;
    if (Number(value) >= 1) {
      // setSize(value);
      dispatch(setPartySize(Number(value)));
    }
  };
  useEffect(() => {
    if (partySize) {
      setCustomerDetail({
        ...customerDetail,
        customer_number: partySize?.customer_number ?? "",
        customer_name: partySize?.customer_name ?? "",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formattedNumber = useMemo(() => {
    return formatPhoneNumber(
      customerDetail.customer_number,
      partySize?.customer_calling_code
    );
  }, [partySize?.customer_calling_code, customerDetail.customer_number]);

  const increaseSize = () => {
    // setSize((oldState) => ++oldState);
    let seatSize = Number(partySize?.value);
    dispatch(setPartySize(++seatSize));
  };
  const decreaseSize = () => {
    // if (size > 1) setSize((oldState) => --oldState);
    let seatSize = Number(partySize?.value);
    if (seatSize > 1) {
      dispatch(setPartySize(--seatSize));
    }
  };

  const setCallingCode = (val) => {
    dispatch(setCustomerCallingCode({ customer_calling_code: val }));
  };
  const setUserInput = (val) => {
    setCustomerDetail({
      ...customerDetail,
      customer_number: val,
      // customer_calling_code: calling_code,
    });
    dispatch(
      setCustomerDetails({
        customer_name: customerDetail?.customer_name,
        customer_number: formattedNumber,
        // customer_calling_code: calling_code,
      })
    );
  };

  const setCustomerName = (val) => {
    setCustomerDetail({ ...customerDetail, customer_name: val });
    dispatch(
      setCustomerDetails({
        customer_name: val,
        customer_number: customerDetail?.customer_number,
        customer_calling_code: customerDetail?.customer_calling_code,
      })
    );
  };

  // Default service type handler
  const checkboxChangeHandler = () => {
    setLoading(true);
    ResturantService.chooseDefaultServiceType(
      {
        type: "skip_customer_info",
        value: true,
      },
      (res) => {
        setLoading(false);
        dispatch(toggleConfig(true));
        setMakeDefault(true);
        dispatch(skipPartySizeAndRemember());
        dispatch(unSkipPartySize());
        toast.success(
          res?.response?.data?.message ||
          `This step has been set to skip successfully`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
      },
      (err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || `Unable to skip this as default.`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
      }
    );
  };
  return (
    <div className="w-full h-full bg-gray-50 xl:px-[5rem] xl:py-[4rem] lg:px-[2.5rem] lg:py-[3rem] md:px-[1.5rem] md:py-[2.5rem] grid grid-rows-[1fr_auto]">
      <div className="w-full">
        {/* Top Info */}
        <div className="w-full">
          <p className="text-gray-800 font-bold lg:text-[16px] text-[14px]">
            Party Size Information
          </p>
        </div>
        <UserInformation
          customerDetail={customerDetail}
          setUserInput={setUserInput}
          setCustomerName={setCustomerName}
          setCallingCode={setCallingCode}
        />
        {/* Number of guest */}
        <div className="w-full mt-20 mb-7">
          <h6 className="text-gray-500 text-[1.5rem] font-Inter font-medium">
            Number of Guests
          </h6>
        </div>
        <div className="w-full flex flex-row   space-x-5">
          <InputContainer className="w-[50%] rounded-[4px] bg-white flex flex-row items-center justify-center border border-gray-300 h-[100px]  lg:h-[130px]">
            <input
              aria-label="party-size-input"
              type="number"
              value={Number(partySize?.value)}
              onChange={(e) => handlePartySize(e)}
              min="1"
              className=" w-full h-full  flex flex-row items-center justify-center mx-auto text-center outline-none focus:outline-none lg:text-[24px] md:text-[20px] font-Inter font-bold"
            />
          </InputContainer>
          {/* Controls */}
          <div className="w-[50%]">
            <PartySizeControl
              increaseSize={increaseSize}
              decreaseSize={decreaseSize}
            />
          </div>
        </div>
      </div>
      {/* Skip info control */}
      <div className="w-full bottom-3">
        <div className=" border-b border-b-gray-400 pb-4">
          <h6 className="text-gray-500 text-[1.5rem] font-Inter font-medium">
            Optional
          </h6>
        </div>
        <div className="w-full mt-4">
          <button
            onClick={() => {
              dispatch(unSkipPartySizeAndRemember());
              dispatch(skipPartySize());
            }}
            className="flex flex-row items-center space-x-4"
          >
            <div className="w-[2rem] h-[2rem] flex flex-row items-center justify-center bg-white border border-gray-400 rounded-full ">
              {partySize?.skipped && !skip_customer_info && (
                <div className="w-[1.5rem] h-[1.5rem] bg-textColor rounded-full" />
              )}
            </div>
            <p className="text-gray-500 text-[1.5rem] font-Inter font-medium">
              Skip
            </p>
          </button>
          <button
            onClick={checkboxChangeHandler}
            className="flex flex-row items-center mt-4 space-x-4"
          >
            <div className="w-[2rem] h-[2rem] flex flex-row items-center justify-center bg-white border border-gray-400 rounded-full ">
              {makeDefault && !partySize?.isSkipped && (
                <div className="w-[1.5rem] h-[1.5rem] bg-textColor rounded-full" />
              )}
            </div>
            <div className="flex flex-row items-center justify-between space-x-5">
              <p className="text-gray-500 text-[1.5rem] font-Inter font-medium">
                Skip & Remember
              </p>
              {loading && (
                <ImSpinner2 className="animate-spin ml-2 text-primary" />
              )}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PartySizeLayout;

const InputContainer = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;
