// import noOrderImg from "assets/img/no-orders.png";
import Icon from "components/Icon/Icon";
import Loader from "components/Loader/Loader";
import useClickOutside from "hooks/useOutsideClick";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchZones, addSelectedZone } from "redux/zone";
import { useNavigate } from "../../../../node_modules/react-router-dom/dist/index";
import { toast } from "react-toastify";
// import classes from "./FloorDefaultScreen.module.scss";
import { fetchZoneTableDetail } from "redux/zoneTable";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import SingleZone from "../AddTable/SingleZone";
import useEventListener from "hooks/useEventListener";
import {
  OUTLET_CREATE_PERMISSION,
  ZONE_CREATE_PERMISSION,
  ZONE_DELETE_PERMISSION,
} from "constants/constants";
import { usePermissionHooks } from "hooks/usePermissionHooks";
import FloorTables from "../components/FloorTables";
import classes from "./MainFloor.module.scss";
import { TableServices } from "services/TableServices";
import { addTable, deleteTable } from "redux/table";
import Modal from "components/Modal/Modal";
import tableClasses from "../UpdateTable/UpdateTable.module.scss";
import { ZoneServices } from "services/ZoneServices";
import useToggle from "hooks/useToggle";

const MainFloor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addBoxRef = useRef(null);
  const [showOptions, toggleOptions] = useState(false);

  // To determine if the logged in user can view create/add a new zone
  const { canView: canAddZone } = usePermissionHooks(ZONE_CREATE_PERMISSION);
  const [isDeleting, toggleisDeleting] = useToggle(false);

  // To determine if the logged in user can view create/add a new zone
  const { canView: canDeleteZone } = usePermissionHooks(ZONE_DELETE_PERMISSION);

  const { canView: canAddOutlet } = usePermissionHooks(
    OUTLET_CREATE_PERMISSION
  );

  const [selectedButton, setSelectedButton] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedState, setSelectedState] = useState("floor");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [proceedWithMerge, setProceedWithMerge] = useState(false);
  const closeModal = () => setIsModalOpen(false)
  const saveModal = () => {
    if (mergeTable && zoneTo) {
      const taleData = {
        "merged_table_id": mergeTable.id,
        "zone_id": zoneTo.id
      };
      setMergeTableSelect(false)

      TableServices.transferTable(
        taleData,
        (res) => {
          toast.success("Table has been successfully transferred!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          dispatch(addTable(res.data.data));
          dispatch(fetchZoneTableDetail({ id: activeZone?.id }));
          setIsModalOpen(false)
          setMergeTable(null)
          setMergeTableSelect(true);
          navigate("/floor");
        },
        (err) => {
          //toast.error("Error transferring table.");

          for (const key in err.response.data.errors) {
            toast.error(err.response.data.errors[key][0], {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      );
    }
    else {
      toast.error("select zone to transfer");

    }
  }
  const deleteTableHandler = () => {

    toggleisDeleting();

    ZoneServices?.deleteZone(
      activeZone?.id,
      (res) => {
        setTimeout(() => {
          toast.success("Zone deleted successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }, 500);

        dispatch(fetchZones());
        navigate("/floor");
      },
      (err) => {
        console.log(err.response, "Err");
        if (err && err?.response?.data?.message) {
          toast.error(err.response.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else
          for (const key in err.response.data.errors) {
            toast.error(err.response.data.errors[key][0], {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
      }
    );
  };
  const cancelMerge = () => {
    setProceedWithMerge(false)
  }

  const canAdd = () => {
    if (!canAddZone) {
      toggleOptions(false);
      toast.warning("You don not have permission to perform this action", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      navigate("zones/add");
    }
  };

  useEventListener(
    "scroll",
    (e) => {
      //   console.log(e, "Event");
    },
    window
  );

  const {
    error: zonesError,
    isLoading: zonesLoading,
    zones,
  } = useSelector((state) => state.zone);
  const {
    zoneTableDetail,
    isLoading: zoneDetailLoading,
    error: zoneDetailError,
  } = useSelector((state) => state.zoneTableDetail);
  const [activeZone, setActiveZone] = useState({});
  const [selectedTables, setSelectedTables] = useState([]);
  // const zone_detail = zones?.filter((zn) => zn.id === selectedZone)?.[0] || {};
  const [showDropdownFrom, setShowDropdownFrom] = useState(false);
  const [showDropdownTo, setShowDropdownTo] = useState(false);
  const [zoneFrom, setZoneFrom] = useState(null);
  const [zoneTo, setZoneTo] = useState(null);
  const [mergeTableSelect, setMergeTableSelect] = useState(false);
  const [mergeTable, setMergeTable] = useState(null);



  useClickOutside(addBoxRef, () => {
    if (showOptions) toggleOptions(false);
  });

  // My code
  const getData = () => {
    dispatch(fetchZones());
  };
  const getTable = () => {
    dispatch(fetchZoneTableDetail({ id: activeZone?.id }));
  };

  // Change viewed zone
  const changeZone = (zone) => {
    setSelectedButton(null)
    setActiveZone(zone);
  };
  const tablesToMerge = () => {
    setSelectedTables([])
    setSelectedState('merge')
    setMergeTableSelect(false);
    setMergeTable(null)

  }
  const transfer = () => {

    if (mergeTable && activeZone.name === "Merged Table Zone") {
      setIsModalOpen(true);
      // Find and set the zone corresponding to the mergeTable's zone_id
      const zone = zones.find((z) => z.id === mergeTable.zone_id);
      if (zone) {
        setZoneFrom(zone);
      } else {
        console.error("Zone not found for the given mergeTable.zone_id");
      }
    } else {
      toast.error("Please select a merged table from Merged Table Zone", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  // const fet

  useEffect(() => {
    dispatch(fetchZones());
  }, [dispatch]);

  useEffect(() => {
    if (zones && zones.length > 0) {
      dispatch(addSelectedZone(Number(zones[0].id)));
      setActiveZone(zones[0]);
    }
  }, [zones, dispatch]);

  useEffect(() => {
    if (zones && zones?.length > 0 && activeZone?.id) {
      dispatch(fetchZoneTableDetail({ id: activeZone?.id }));
    }
  }, [activeZone, dispatch, zones]);
  // End if my codes

  // const defaultScreen = (
  //   <div classNaMainFloor}>
  //     <ontainer />
  //     <div className={classes.GetStarted}>
  //       <img src={noOrderImg} alt="order pic here" />
  //       <p className={classes.GetStartedHeading}>
  //         <span>Start New Order</span> <span>ابدأ طلب جديد</span>
  //       </p>
  //       <p className={classes.GetStartedText}>
  //         Any assets used in projects will live here.
  //       </p>
  //       <p className={classes.GetStartedText}>
  //         Start creating by uploading your files.
  //       </p>
  //       <div className={classes.GetStartedActions}>
  //         <button className={classes.SupprtBtn}>
  //           <span>Support article</span>
  //         </button>
  //         <div ref={addBoxRef} className={classes.AddBox}>
  //           <button onClick={toggleOptions} className={classes.AddBtn}>
  //             <Icon name="upload" />
  //             <span>Add</span>
  //           </button>
  //           {showOptions && (
  //             <ul className={classes.AddOptions}>
  //               <li>
  //                 <button onClick={() => navigate("zones/add")}>
  //                   Add Zone
  //                 </button>
  //               </li>
  //               {zones?.length > 0 && (
  //                 <li>
  //                   <button onClick={() => navigate("tables/add")}>
  //                     Add Table
  //                   </button>
  //                 </li>
  //               )}
  //             </ul>
  //           )}
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  // Delete zone function


  const handleCancel = () => {
    setSelectedTables([])
    setSelectedState('floor')
    setShowConfirmation(false)
    setProceedWithMerge(false)
    setMergeTableSelect(true);
    setMergeTable(null)



  };
  const handleSelectTable = (table) => {
    const isSelected = selectedTables.some((t) => t.id === table.id);
    if (isSelected) {
      setSelectedTables(selectedTables.filter((t) => t.id !== table.id));
    } else {
      setSelectedTables([...selectedTables, table]);
    }
  };
  const handleSelectMergeTable = (table) => {
    setMergeTable(table)
  };

  const handleTableDeleted = () => {
    getTable();
  };
  const handleMergeTables = () => {
    setSelectedButton(4)
    if (selectedTables.length < 2) {
      toast.error("Please select at least two tables to merge.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    if (!proceedWithMerge) {
      const hasOccupiedTables = selectedTables.some((table) => table.status === "occupied");
      if (hasOccupiedTables && !proceedWithMerge) {
        setShowConfirmation(true);
        setProceedWithMerge(true)
        return;
      }
    }
    try {

      const table = {
        table_ids: selectedTables.map(item => item.id),
      };
      TableServices.mergeTable(
        table,
        (res) => {
          toast.success("Tables have been successfully merged!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          dispatch(addTable(res.data.data));
          selectedTables.forEach(table => {
            dispatch(deleteTable(table.id));
          })
          dispatch(fetchZones())
          dispatch(fetchZoneTableDetail({ id: activeZone?.id }));
          setProceedWithMerge(false)
          navigate("/floor");
        },
        (err) => {
          setProceedWithMerge(false)
          if (err && err?.response?.data?.message) {
            toast.error(err.response.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else
            for (const key in err.response.data.errors) {
              toast.error(err.response.data.errors[key][0], {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
        }
      );
      setSelectedTables([]);

    } catch (error) {
      toast.error("Error merging tables. Please try again.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

  };
  const sortedZones = [...zones].sort((a, b) =>
    a.name === "Merged Table Zone" ? -1 : b.name === "Merged Table Zone" ? 1 : 0
  );
  const showZone = [...zones].filter(x => x.name !== 'Merged Table Zone')

  return (
    <div>
      <Modal
        onClickOutside={toggleisDeleting}
        background="dark"
        show={isDeleting}
        customClasses={tableClasses.CustomModal}
      >
        <Icon name="alert-circle-2" width={4.8} height={4.8} />
        <p>{`Delete [${activeZone?.name}] ?`}</p>
        <p>
          Doing so will also delete all tables contained in the zone. This
          action cannot be undone!
        </p>
        <div className={tableClasses.ModalActions}>
          <button
            className={tableClasses.ModalCancelBtn}
            onClick={toggleisDeleting}
          >
            Cancel
          </button>
          <button
            className={tableClasses.ModalProceedBtn}
            onClick={deleteTableHandler}
          >
            Delete
          </button>
        </div>
      </Modal>

      <div className="w-full h-full bg-gray-50 xl:px-[2rem] xl:py-[4rem] lg:px-[2rem] lg:py-[3rem] md:px-[1rem] md:py-[2.5rem]">
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-[2.5rem] font-bold text-black mb-2">
              {selectedState === "merge" ? "Merged Tables" : "Floor Plan"}
            </h2>
          </div>
          {selectedState === "floor" && (<div className={classes.ButtonContent} >
            <button className={`${classes.FunctionButton} ${selectedButton === 1 ? classes.selected : ""}`}
              onClick={() => tablesToMerge()}  >Merged Tables</button>

            <button className={`${classes.FunctionButton} ${selectedButton === 2 ? classes.selected : ""}`} onClick={canAdd} >Add Zone</button>
            <button className={`${classes.FunctionButton} ${selectedButton === 3 ? classes.selected : ""}`}
              onClick={
                !canAddOutlet
                  ? () => {
                    toggleOptions(false);
                    toast.warning(
                      "You don not have permission to perform this action",
                      {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      }
                    );
                  }
                  : () => {
                    toggleOptions(false);
                    navigate("tables/add");
                  }
              }
            >Add Table</button>
            <button className={`${classes.FunctionButton} ${selectedButton === 4 ? classes.selected : ""}`} onClick={
              !canDeleteZone
                ? () => {
                  toast.warning(
                    "You don not have permission to perform this action",
                    {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    }
                  );
                }
                : toggleisDeleting
            } >Delete Zone</button>
          </div>)}
          {selectedState === "merge" && (<div className={classes.ButtonContent} >
            <button className={`${classes.FunctionButton} ${selectedButton === 5 ? classes.selected : ""}`}
              onClick={() => transfer()}  >Transfer</button>

            {/* <button className={`${classes.FunctionButton} ${selectedButton === 6 ? classes.selected : ""}`} onClick={canAdd} >Unmerge All</button> */}
            <button className={`${classes.FunctionButton} ${selectedButton === 7 ? classes.selected : ""}`}
              onClick={handleCancel}
            >Cancel</button>
            <button className={`${classes.FunctionButton} ${selectedButton === 8 ? classes.selected : ""}`} onClick={handleMergeTables} >Save</button>
          </div>)
          }

        </div>
        {/* Confirmation Banner */}

        {showConfirmation && (
          <div className="w-full bg-white-100 border border-black p-4 mb-4 rounded-lg flex justify-between items-center mt-4">
            <span className="text-black-700 font-semibold">
              Several tables in your selection may be occupied. Do you want to continue to merge?
            </span>
            <div className="flex space-x-4">
              <button
                onClick={() => {
                  setShowConfirmation(false);
                  handleMergeTables();
                }}
                className="px-4 py-2 bg-gray-300 rounded"
              >
                Yes
              </button>
              <button
                onClick={() => {
                  setShowConfirmation(false);
                  cancelMerge();
                }
                }
                className="px-4 py-2 bg-gray-300 rounded"
              >
                No
              </button>
            </div>
          </div>
        )
        }
        <div className="w-full h-full flex lg:flex-row flex-col-reverse justify-between lg:space-x-16 space-x-5 ">
          {/* Table Zone */}
          <div className=" w-full lg:w-[80%]  h-full">
            <div>

              <h6 className="text-[1.5rem] font-semibold text-gray-500 mb-2">
                Manage your zones and tables from this view.
              </h6>
            </div>
            {/* {activeZone && activeZone?.name && (
            <h6 className="text-gray-700 text-[16px] font-semibold mb-4">
              {activeZone?.name}
            </h6>
          )} */}
            <div className=" w-full bg-[#E5E7EB] h-full mt-4">
              {selectedState === 'floor' && (<div className="flex items-center p-4 justify-between ">
                {/* Vacant */}
                <div className="flex items-center">
                  <span className="w-14 h-6 bg-green-600 rounded-full mr-2"></span>
                  <p className="text-base font-medium text-gray-600">Vacant</p>
                </div>

                {/* Occupied */}
                <div className="flex items-center">
                  <span className="w-14 h-6 bg-orange-500 rounded-full mr-2"></span>
                  <p className="text-base font-medium text-gray-600">Occupied</p>
                </div>

                {/* Reserved */}
                <div className="flex items-center">
                  <span className="w-14 h-6 bg-blue-500 rounded-full mr-2"></span>
                  <p className="text-base font-medium text-gray-600">Reserved</p>
                </div>
                {/* Disabled */}
                <div className="flex items-center">
                  <span className="w-14 h-6 border border-black rounded-full mr-2"></span>
                  <p className="text-base font-medium text-gray-600">Disabled</p>
                </div>
              </div>)}


              {activeZone?.id || activeZone.active === 'none' ? (
                <FloorTables
                  zoneTableDetail={zoneTableDetail}
                  zoneDetailLoading={zoneDetailLoading}
                  zoneDetailError={zoneDetailError}
                  getTable={getTable}
                  activeZone={activeZone}
                  zones={zones}
                  selectedTables={selectedTables}
                  onSelectTable={handleSelectTable}
                  selectedState={selectedState}
                  onTableDeleted={handleTableDeleted}
                  clearSelection={mergeTableSelect}
                  onSelectMergeTable={handleSelectMergeTable}

                />
              ) : (<div className="w-[80%] h-full flex flex-col items-center justify-center mx-auto">
                <p className="pt-5 text-3xl text-center font-Inter font-medium text-textColor">
                  <span>
                    No zones available. Please contact your Manager to add
                    zones.
                  </span>
                </p>
              </div>)}

            </div>
          </div>
          {/* Zones */}
          <div className={`w-full lg:w-[20%] overflow-y-auto h-[500px] ${classes.scrollable}`}>
            {/* <h6 className="text-gray-800 text-3xl font-bold">All Zones</h6> */}

            <div className="w-full lg:h-full  min-w-full">
              {/* Loading view */}
              {zonesLoading && !zonesError && (
                <div className="w-full h-full flex flex-col items-center justify-center">
                  <Loader />
                </div>
              )}
              {/* Error view */}
              {!zonesLoading && zonesError && (
                <div className="w-full h-full flex flex-col items-center justify-center">
                  <p className="text-2xl lg:text-2xl font-Inter font-medium text-red-300 text-center">
                    Error occurred while fetching your zones
                  </p>
                  <div className="mt-10 w-full flex flex-row items-center justify-center">
                    <PrimaryButton
                      text="Try Again"
                      onClicked={() => {
                        getData();
                      }}
                    />
                  </div>
                </div>
              )}
              {/* Empty view */}
              {!zonesError && !zonesLoading && zones?.length === 0 && (
                <div className="w-full h-full flex flex-col items-center justify-center">
                  <p className="pt-5 text-2xl font-Inter font-medium text-textColor">
                    <span>
                      No zones available. Please contact your Manager to add
                      zones.
                    </span>
                  </p>
                </div>
              )}
              {/* Zones view */}
              {!zonesError && !zonesLoading && zones?.length > 0 && (
                <div className="w-full  grid lg:flex grid-cols-4 gap-5 lg:flex-col items-center lg:space-y-4 mt-14 lg:mb-0 mb-8  min-w-full">
                  {/* <button
                  className={`w-full rounded-md flex items-center justify-center border h-[70px] ${mergeTableSelect === 'merge-tables' ?
                    'w-full bg-textColor border border-textColor text-white' :
                    'w-full border border-gray-300'}`}
                  onClick={() => changeZone('merge-tables')}
                >
                  <p
                    className={
                      mergeTableSelect === 'merge-tables'
                        ? "text-[1.5rem] text-white font-bold "
                        : "text-[1.5rem] text-textColor font-bold "
                    }
                  >
                    {mergeTableSelect === 'merge-tables' ? 'Merged Tables' : 'Merged Tables'}

                  </p>
                </button> */}
                  {sortedZones.map((item, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        dispatch(addSelectedZone(item?.id));
                        changeZone(item);
                      }}
                      className={
                        activeZone?.id === item?.id
                          ? "w-full rounded-md flex flex-row items-center justify-center border bg-textColor border-textColor  h-[70px]"
                          : "w-full rounded-md flex flex-row items-center justify-center border border-gray-300 h-[70px]"
                      }
                    >
                      <p
                        className={
                          activeZone?.id === item.id
                            ? "text-[1.5rem] text-white font-bold "
                            : "text-[1.5rem] text-textColor font-bold "
                        }
                      >
                        {item?.name}
                      </p>
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        {
          isModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-8 rounded-lg shadow-lg w-[600px] h-[280px] max-w-full max-h-full">
                <h2 className="text-xxl font-semibold mb-4">
                  Select zones to transfer tables
                </h2>

                <label className="block font-semibold mb-2">From</label>
                <div className="relative mt-6">
                  <div
                    className="border rounded-md p-3 cursor-pointer flex justify-between items-center"
                  //onClick={() => setShowDropdownFrom(!showDropdownFrom)}
                  >
                    <span>
                      <span>{zoneFrom?.name || "Select Zone"}</span>
                    </span>
                    <Icon name="chevron-down" />
                  </div>
                  {showDropdownFrom && (
                    <div className="absolute bg-white border border-gray-300 w-full mt-2 max-h-60 overflow-auto rounded-md shadow-lg z-50">
                      {zones.map((zone) => {
                        return (
                          <SingleZone
                            key={zone.id}
                            {...zone}
                            active={zone.id === zoneFrom?.id}
                            onClick={() => {
                              setZoneFrom(zone);
                              setShowDropdownFrom(false);
                              dispatch(addSelectedZone(zone.id));
                            }}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>

                <label className="block font-semibold mb-2">To</label>
                <div className="relative mt-6">
                  <div
                    className="border rounded-md p-3 cursor-pointer flex justify-between items-center"
                    onClick={() => setShowDropdownTo(!showDropdownTo)}
                  >
                    <span>
                      {zoneTo ? zoneTo.name : "Select zone"}
                    </span>
                    <Icon name="chevron-down" />
                  </div>
                  {showDropdownTo && (
                    <div className="absolute bg-white border border-gray-300 w-full mt-2 max-h-60 overflow-auto rounded-md shadow-lg z-50">
                      {showZone.map((zone) => (
                        <SingleZone
                          key={zone.id}
                          {...zone}
                          active={zone.id === zoneTo?.id}
                          onClick={() => {
                            setZoneTo(zone);
                            setShowDropdownTo(false);
                            dispatch(addSelectedZone(zone.id));
                          }}
                        />
                      ))}
                    </div>
                  )}
                </div>

                <div className="flex justify-between">
                  <button
                    onClick={closeModal}
                    className={classes.ModalBtn}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      saveModal();
                      // Add logic to handle transfer action
                    }}
                    className={classes.ModalBtn}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )
        }
      </div >
    </div>
  );
};

export default MainFloor;
