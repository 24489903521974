// import PartySizeModal from "components/PartySizeModal/PartySizeModal";
// import useToggle from "hooks/useToggle";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GlobalPadding from "components/Layout/globalContainer/GlobalPadding";
import AllocationStepContainer from "./layout/AllocationStepContainer";
import { useEffect, useState } from "react";
import PartySizeLayout from "./layout/partySizeLayout/PartySizeLayout";
import TableAllocationLayout from "./layout/tableAllocationLayout/TableAllocationLayout";
import { useDefaultSetting } from "hooks/useDefaultSetting";
// import { useCallback, useEffect } from "react";
// import { useDefaultSetting } from "hooks/useDefaultSetting";

const AllocationSteps = () => {
  // const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  const { skip_customer_info } = useDefaultSetting();

  const [activeStep, setActiveStep] = useState(
    location?.state?.step ?? "party-size"
  );

  const changeStep = (val) => {
    setActiveStep(val);
  };

  useEffect(() => {
    if (skip_customer_info) {
      setActiveStep("table-allocation");
    }
  }, [skip_customer_info]);

  return (
    <GlobalPadding
      hideTopPadding={true}
      hideLeftPadding={true}
      hideRightPadding={true}
    >
      <div className="w-full h-full ">
        {/* <PartySizeModal show={showPartySizeModal} toggleModal={toggleModal} /> */}
        <div className="w-full h-[90vh] grid  lg:grid-cols-[30%_70%] md:grid-cols-[40%_60%]">
          <div className="w-full h-full bg-white px-[2rem] pt-[3rem]">
            <div className="w-full">
              <h2 className="font-Inter text-3xl text-textColor font-medium">
                {t("CREATE_A_NEW_ORDER")}
              </h2>
              <p className="text-placeholder font-Inter text-xl pt-2">
                Complete the two steps and a new order will be created
                automatically at the end.
              </p>
            </div>
            <AllocationStepContainer
              active={activeStep}
              changeStep={changeStep}
            />
          </div>
          <div className="w-full">
            {activeStep === "party-size" && <PartySizeLayout />}
            {activeStep === "table-allocation" && <TableAllocationLayout />}
          </div>
        </div>
      </div>
    </GlobalPadding>
  );
};

export default AllocationSteps;
