import Loader from "components/Loader/Loader";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { addSelectedZone, fetchZones } from "redux/zone";
import { fetchZoneTableDetail } from "redux/zoneTable";
import classes from "./MergeTables.module.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TableServices } from "services/TableServices";
import { addTable } from "redux/table";
import FloorTables from "../../../Floor/components/FloorTables";

const MergeTables = () => {
    const dispatch = useDispatch();
    // const navigate = useNavigate();
    const [activeZone, setActiveZone] = useState({});
    const [selectedTables, setSelectedTables] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [proceedWithMerge, setProceedWithMerge] = useState(false);
    const {
        error: zonesError,
        isLoading: zonesLoading,
        zones,
        selectedZone,
    } = useSelector((state) => state.zone);
    const {
        zoneTableDetail,
        isLoading: zoneDetailLoading,
        error: zoneDetailError,
    } = useSelector((state) => state.zoneTableDetail);

    // Change viewed zone
    const changeZone = (zone) => {
        setSelectedButton(null)
        setActiveZone(zone);
    };
    // console.log(zones);
    const getData = () => {
        dispatch(fetchZones());
    };
    const getTable = () => {
        dispatch(fetchZoneTableDetail({ id: activeZone?.id }));
    };

    useEffect(() => {
        dispatch(fetchZones());
    }, [dispatch]);

    // Choosing Default Zone
    const chooseDefaultZone = useCallback(() => {
        //console.log('default zone', selectedZone)
        if (zones && zones.length > 0) {
            if (selectedZone) {
                const activeZone = zones?.filter(
                    (item) => item?.id === selectedZone
                )[0];
                if (activeZone) {
                    setActiveZone(activeZone);
                } else {
                    dispatch(addSelectedZone(Number(zones[0].id)));
                    setActiveZone(zones[0]);
                }
            } else {
                dispatch(addSelectedZone(Number(zones[0].id)));
                setActiveZone(zones[0]);
            }
        }

    }, [zones, dispatch, selectedZone]);
    const navigate = useNavigate();

    useEffect(() => {
        chooseDefaultZone();
    }, [chooseDefaultZone]);

    useEffect(() => {
        if (zones && zones?.length > 0 && activeZone?.id) {
            dispatch(fetchZoneTableDetail({ id: activeZone?.id }));
        }
    }, [activeZone, dispatch, zones]);

    const sortedZones = [...zones].sort((a, b) =>
        a.name === "Merged Table Zone" ? -1 : b.name === "Merged Table Zone" ? 1 : 0
      );
    const [selectedButton, setSelectedButton] = useState(null);
    const handleSelectTable = (table) => {
        const isSelected = selectedTables.some((t) => t.id === table.id);
        if (isSelected) {
            setSelectedTables(selectedTables.filter((t) => t.id !== table.id));
        } else {
            setSelectedTables([...selectedTables, table]);
        }
    };

    const handleSelectAll = async (zones) => {
        setSelectedButton(3); // Example: Highlight the "Select All" button.

        try {
            // Fetch details for all zones concurrently.
            const results = await Promise.all(
                zones.map(async (zone) => {
                    const zoneTables = await dispatch(fetchZoneTableDetail({ id: zone?.id }));

                    if (zoneTables && zoneTables.payload?.data) {
                        const { vacant_table = [], occupied_table = [] } = zoneTables.payload.data;

                        // Filter tables to exclude those with the merged_tables field
                        const filteredVacantTables = vacant_table.filter(table => !table.merged_tables || table.merged_tables.length === 0);
                        const filteredOccupiedTables = occupied_table.filter(table => !table.merged_tables || table.merged_tables.length === 0);

                        return {
                            vacant: filteredVacantTables,
                            occupied: filteredOccupiedTables,
                        };
                    }
                    return { vacant: [], occupied: [] };
                })
            );

            // Consolidate vacant and occupied tables.
            const nonReservedTables = results.flatMap((result) => [
                ...result.vacant,
                ...result.occupied,
            ]);
            setSelectedTables((prev) => [
                ...prev,
                ...nonReservedTables.filter(
                    (newTable) => !prev.some((table) => table.id === newTable.id)
                ),
            ]);
            dispatch(fetchZoneTableDetail({ id: activeZone?.id }));
            toast.success("All available tables have been selected.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        } catch (error) {
            console.error("Error selecting all tables:", error);
            toast.error("Failed to select tables.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    };



    // Clear all selections
    const handleClearSelection = () => {
        setSelectedButton(2)
        setShowConfirmation(false)
        setProceedWithMerge(false)
        setSelectedTables([]);
        toast.info("All selections have been cleared.", {
            position: toast.POSITION.BOTTOM_RIGHT,
        });
    };

    // Merge selected tables via API
    const handleMergeTables = () => {
        setSelectedButton(4)
        if (selectedTables.length < 2) {

            toast.error("Please select at least two tables to merge.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            return;
        }
        if (!proceedWithMerge) {
            const hasOccupiedTables = selectedTables.some((table) => table.status === "occupied");
            if (hasOccupiedTables && !proceedWithMerge) {
                setShowConfirmation(true);
                setProceedWithMerge(true)
                return;
            }
        }
        try {
            const table = {
                table_ids: selectedTables.map(item => item.id),
            };
            TableServices.mergeTable(
                table,
                (res) => {
                    toast.success("Tables have been successfully merged!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    dispatch(addTable(res.data.data));
                    setProceedWithMerge(false)

                    navigate("/orders/create/allocation-steps");
                },
                (err) => {
                    setProceedWithMerge(false)
                    if (err && err?.response?.data?.message) {
                        toast.error(err.response.data.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    } else
                        for (const key in err.response.data.errors) {
                            toast.error(err.response.data.errors[key][0], {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                        }
                    //toast.error("Error merging table.");
                }
            );
            setSelectedTables([]);

        } catch (error) {
            toast.error("Error merging tables. Please try again.", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    };
    const cancelMerge = () => {
        setProceedWithMerge(false)
    }

    // Cancel and redirect
    const handleCancel = () => {
        setSelectedButton(1)
        navigate("/orders/create/allocation-steps");
    };
    const handleTableDeleted = () => {
        getTable();
    };
    return (

        <div className="w-full h-full bg-gray-50 xl:px-[2rem] xl:py-[4rem] lg:px-[2rem] lg:py-[3rem] md:px-[1rem] md:py-[2.5rem]">

            <div className="flex items-center justify-between">
                <div>
                    <h2 className="text-[2.5rem] font-bold text-black mb-2">
                        Merge Tables
                    </h2>
                    <h6 className="text-[1.5rem]  text-gray-700 mb-4">
                        Below are the tables you can merge in
                        {activeZone?.name ? ` - ${activeZone.name}` : ""}
                    </h6>

                </div>
                <div className={classes.ButtonContent} >
                    <button className={`${classes.FunctionButton} ${selectedButton === 1 ? classes.selected : ""}`}
                        onClick={handleCancel} >Cancel</button>

                    <button className={`${classes.FunctionButton} ${selectedButton === 2 ? classes.selected : ""}`} onClick={handleClearSelection}>Clear</button>
                    <button className={`${classes.FunctionButton} ${selectedButton === 3 ? classes.selected : ""}`} onClick={() => handleSelectAll(zones)} >Select All</button>
                    <button className={`${classes.FunctionButton} ${selectedButton === 4 ? classes.selected : ""}`} onClick={handleMergeTables} >Merge Selected</button>
                </div>
            </div>
            {/* Confirmation Banner */}
            {showConfirmation && (
                <div className="w-full bg-white-100 border border-black p-4 mb-4 rounded-lg flex justify-between items-center mt-4">
                    <span className="text-black-700 font-semibold">
                        Several tables in your selection may be occupied. Do you want to continue to merge?
                    </span>
                    <div className="flex space-x-4">
                        <button
                            onClick={() => {
                                setShowConfirmation(false);
                                handleMergeTables(); // Call merge function again to proceed
                            }}
                            className="px-4 py-2 bg-gray-300 rounded"
                        >
                            Yes
                        </button>
                        <button
                            onClick={() => {
                                setShowConfirmation(false);
                                cancelMerge();
                            }
                            }
                            className="px-4 py-2 bg-gray-300 rounded"
                        >
                            No
                        </button>
                    </div>
                </div>
            )}
            <div className="w-full h-full flex lg:flex-row flex-col-reverse justify-between items-start lg:space-x-16 space-y-5 lg:space-y-0">
                {/* Table Zone */}
                <div className="w-full lg:w-[80%] h-full flex flex-col justify-start">
                    <div className="w-full bg-[#E5E7EB] h-full mt-4">
                        {activeZone?.id || activeZone.active === 'none' ? (
                            <FloorTables
                                zoneTableDetail={zoneTableDetail}
                                zoneDetailLoading={zoneDetailLoading}
                                zoneDetailError={zoneDetailError}
                                getTable={getTable}
                                activeZone={activeZone}
                                zones={zones}
                                selectedTables={selectedTables}
                                onSelectTable={handleSelectTable}
                                onTableDeleted={handleTableDeleted}

                            />
                        ) : (
                            <div className="w-full h-full flex flex-col items-center justify-center mx-auto">
                                <p className="pt-5 text-3xl text-center font-Inter font-medium text-textColor">
                                    No zones available. Please contact your Manager to add zones.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                {/* Zones */}
                <div className={`w-full lg:w-[20%] overflow-y-auto h-[500px] ${classes.scrollable}`}>
                    {zonesLoading && !zonesError ? (
                        <div className="w-full h-full flex flex-col items-center justify-center">
                            <Loader />
                        </div>
                    ) : zonesError ? (
                        <div className="w-full h-full flex flex-col items-center justify-center">
                            <p className="text-2xl lg:text-2xl font-Inter font-medium text-red-300 text-center">
                                Error occurred while fetching your zones
                            </p>
                            <div className="mt-10">
                                <PrimaryButton
                                    text="Try Again"
                                    onClicked={() => {
                                        getData();
                                    }}
                                />
                            </div>
                        </div>
                    ) : zones?.length === 0 ? (
                        <div className="w-full h-full flex flex-col items-center justify-center">
                            <p className="pt-5 text-2xl font-Inter font-medium text-textColor">
                                No zones available. Please contact your Manager to add zones.
                            </p>
                        </div>
                    ) : (
                        <div className="w-full  grid lg:flex grid-cols-4 gap-5 lg:flex-col items-center lg:space-y-4 mt-14 lg:mb-0 mb-8  min-w-full">
                            {/* <button
                                className={`w-full rounded-md flex items-center justify-center border h-[70px] ${mergeTableSelect === 'merge-tables' ?
                                    'w-full bg-textColor border border-textColor text-white' :
                                    'w-full border border-gray-300'}`}
                                onClick={() => changeZone('merge-tables')}
                            >
                                <p className="text-[1.5rem] font-bold">
                                    {mergeTableSelect === 'merge-tables' ? 'Merged Tables' : 'Merged Tables'}
                                </p>
                            </button> */}
                            {sortedZones.map((item, index) => (
                                <button
                                    key={index}
                                    onClick={() => {
                                        dispatch(addSelectedZone(item.id));
                                        changeZone(item);
                                    }}
                                    className={`w-full rounded-md flex items-center justify-center border h-[70px] ${activeZone?.id === item.id
                                        ? 'bg-textColor text-white border-textColor'
                                        : 'border-gray-300 text-textColor'
                                        }`}
                                >
                                    <p className="text-[1.5rem] font-bold">{item.name}</p>
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
};

export default MergeTables;
