import Loader from "components/Loader/Loader";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { addSelectedZone, fetchZones } from "redux/zone";
import { fetchZoneTableDetail } from "redux/zoneTable";
import ZoneTables from "../../components/ZoneTables";
import classes from "./TableAllocationLayout.module.scss";
import { useNavigate } from "react-router-dom";
import MergeIcon from "assets/svg/arrowsMerge.svg";
const TableAllocationLayout = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { table: selectedTable } = useSelector((state) => state.createOrder);
  const [activeZone, setActiveZone] = useState({});
  const {
    error: zonesError,
    isLoading: zonesLoading,
    zones,
    selectedZone,
  } = useSelector((state) => state.zone);
  const {
    zoneTableDetail,
    isLoading: zoneDetailLoading,
    error: zoneDetailError,
  } = useSelector((state) => state.zoneTableDetail);

  // Change viewed zone
  const changeZone = (zone) => {

    // Fetch data from the backend for other zones as usual
    setActiveZone(zone);

    //setActiveZone(zone);
  };
  // console.log(zones);
  const getData = () => {
    dispatch(fetchZones());
  };
  const getTable = () => {
    //console.log(selectedTable)
    dispatch(fetchZoneTableDetail({ id: activeZone?.id }));
  };

  useEffect(() => {
    dispatch(fetchZones());
  }, [dispatch]);
  const navigate = useNavigate();
  // Choosing Default Zone
  const chooseDefaultZone = useCallback(() => {
    if (zones && zones.length > 0) {
      if (selectedZone) {
        const activeZone = zones?.filter(
          (item) => item?.id === selectedZone
        )[0];
        if (activeZone) {
          setActiveZone(activeZone);
        } else {
          dispatch(addSelectedZone(Number(zones[0].id)));
          setActiveZone(zones[0]);
        }
      } else {
        dispatch(addSelectedZone(Number(zones[0].id)));
        setActiveZone(zones[0]);
      }
    }
  }, [zones, dispatch, selectedZone]);

  const handleMergeButtonClick = () => {
    return navigate("/orders/create/merge-tables");
  };
  useEffect(() => {
    chooseDefaultZone();
  }, [chooseDefaultZone]);

  useEffect(() => {
    if (zones && zones?.length > 0 && activeZone?.id) {
      dispatch(fetchZoneTableDetail({ id: activeZone?.id }));
    }
  }, [activeZone, dispatch, zones]);
  const handleTableDeleted = () => {
    getTable();
  };
  const sortedZones = [...zones].sort((a, b) =>
    a.name === "Merged Table Zone" ? -1 : b.name === "Merged Table Zone" ? 1 : 0
  );
  return (
    <div className="w-full h-full bg-gray-50 xl:px-[2rem] xl:py-[4rem] lg:px-[2rem] lg:py-[3rem] md:px-[1rem] md:py-[2.5rem]">
      <div className="w-full h-full flex lg:flex-row flex-col-reverse justify-between lg:space-x-16 space-x-5 ">
        {/* Table Zone */}
        <div className=" w-full lg:w-[80%]  h-full ">
          <div>
            <h2 className="text-[2.5rem] font-bold text-black mb-2">
              {activeZone?.name ? ` ${activeZone.name} ` : ""}
            </h2>
            <h6 className="text-[1.5rem] font-semibold text-gray-500 mb-2">
              Please Select a Table for the seating.
            </h6>
          </div>
          {/* {activeZone && activeZone?.name && (
            <h6 className="text-gray-700 text-[16px] font-semibold mb-4">
              {activeZone?.name}
            </h6>
          )} */}
          <div className=" w-full bg-[#E5E7EB] h-full">
            <div className="flex items-center p-4 justify-between ">
              {/* Vacant */}
              <div className="flex items-center">
                <span className="w-14 h-6 bg-green-600 rounded-full mr-2"></span>
                <p className="text-base font-medium text-gray-600">Vacant</p>
              </div>

              {/* Occupied */}
              <div className="flex items-center">
                <span className="w-14 h-6 bg-orange-500 rounded-full mr-2"></span>
                <p className="text-base font-medium text-gray-600">Occupied</p>
              </div>

              {/* Reserved */}
              <div className="flex items-center">
                <span className="w-14 h-6 bg-blue-500 rounded-full mr-2"></span>
                <p className="text-base font-medium text-gray-600">Reserved</p>
              </div>
            </div>


            {activeZone?.id || activeZone.active === 'none' ? (
              <ZoneTables
                zoneTableDetail={zoneTableDetail}
                zoneDetailLoading={zoneDetailLoading}
                zoneDetailError={zoneDetailError}
                getTable={getTable}
                activeZone={activeZone}
                zones={zones}
                onTableDeleted={handleTableDeleted}
              />
            ) : (<div className="w-[80%] h-full flex flex-col items-center justify-center mx-auto">
              <p className="pt-5 text-3xl text-center font-Inter font-medium text-textColor">
                <span>
                  No zones available. Please contact your Manager to add
                  zones.
                </span>
              </p>
            </div>)}

          </div>
        </div>
        {/* Zones */}
        <div className={`w-full lg:w-[20%] overflow-y-auto h-[500px] ${classes.scrollable}`}>
          {/* <h6 className="text-gray-800 text-3xl font-bold">All Zones</h6> */}
          <button
            onClick={handleMergeButtonClick}
            className={classes.MergeButton}
          >
            <span className={classes.MergeIcon}>
              <img src={MergeIcon} alt="Merge Icon" className="w-15 h-15" />
            </span>
            Merge Tables
          </button>
          <div className="w-full lg:h-full  min-w-full overflow-y-auto h-[500px] ">
            {/* Loading view */}
            {zonesLoading && !zonesError && (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <Loader />
              </div>
            )}
            {/* Error view */}
            {!zonesLoading && zonesError && (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <p className="text-2xl lg:text-2xl font-Inter font-medium text-red-300 text-center">
                  Error occurred while fetching your zones
                </p>
                <div className="mt-10 w-full flex flex-row items-center justify-center">
                  <PrimaryButton
                    text="Try Again"
                    onClicked={() => {
                      getData();
                    }}
                  />
                </div>
              </div>
            )}
            {/* Empty view */}
            {!zonesError && !zonesLoading && zones?.length === 0 && (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <p className="pt-5 text-2xl font-Inter font-medium text-textColor">
                  <span>
                    No zones available. Please contact your Manager to add
                    zones.
                  </span>
                </p>
              </div>
            )}
            {/* Zones view */}
            {!zonesError && !zonesLoading && zones?.length > 0 && (
              <div className="w-full  grid lg:flex grid-cols-4 gap-5 lg:flex-col items-center lg:space-y-4 mt-4 lg:mb-0 mb-8  min-w-full">
                {/* <button
                  className={`w-full rounded-md flex items-center justify-center border h-[70px] ${mergeTableSelect === 'merge-tables' ?
                    'w-full bg-textColor border border-textColor text-white' :
                    'w-full border border-gray-300'}`}
                  onClick={() => changeZone('merge-tables')}
                >
                  <p className="text-[1.5rem] font-bold">
                    {mergeTableSelect === 'merge-tables' ? 'Merged Tables' : 'Merged Tables'}
                  </p>
                </button> */}
                {sortedZones.map((item, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      dispatch(addSelectedZone(item?.id));
                      changeZone(item);
                    }}
                    className={
                      activeZone?.id === item?.id
                        ? "w-full rounded-md flex flex-row items-center justify-center border bg-textColor border-textColor  h-[70px]"
                        : "w-full rounded-md flex flex-row items-center justify-center border border-gray-300 h-[70px]"
                    }
                  >
                    <p
                      className={
                        activeZone?.id === item.id
                          ? "text-[1.5rem] text-white font-bold "
                          : "text-[1.5rem] text-textColor font-bold "
                      }
                    >
                      {item?.name}
                    </p>
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableAllocationLayout;
