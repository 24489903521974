import Backdrop from "components/Backdrop/Backdrop";
import Loader from "components/Loader/Loader";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import PrimaryInputField from "components/PrimaryInputField/PrimaryInputField";
// import PrimarySelectField from "components/PrimarySelectField/PrimarySelectField";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { addZone } from "redux/zone";
import { ZoneServices } from "services/ZoneServices";
import { useNavigate } from "../../../../node_modules/react-router-dom/dist/index";
import classes from "./AddZone.module.scss";
import GlobalPadding from "components/Layout/globalContainer/GlobalPadding";

// const FACILITIES = [
//   "Smoking allowed",
//   "No smoking",
//   "Outdoor Heating",
//   "Outdoor Cooling",
//   "Pets Allowed",
//   "No Pets",
//   "Kids Friendly",
//   "Play area",
// ];

const AddZone = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [area, setArea] = useState(0);
  // const [facilities, setFacilities] = useState([]);
  const [zoneType, setZoneType] = useState("in-door");
  const [isLoading, setIsLoading] = useState(false);

  // const addFicility = (facility) => {
  //   let index = facilities.findIndex((item) => item === facility);
  //   let allFacillities = [...facilities];

  //   if (index > -1) {
  //     allFacillities.splice(index, 1);
  //   } else {
  //     allFacillities.push(facility);
  //   }
  //   setFacilities(allFacillities);
  // };

  // const getFacilityStyleClass = (facility) => {
  //   return facilities.includes(facility)
  //     ? classes.FacilitySelected
  //     : classes.Facility;
  // };

  const onProceedHandler = () => {
    setIsLoading(true);

    const zone = {
      name,
      type: zoneType,
      is_active: 1,
      area: Number(area),
    };

    ZoneServices.createZone(
      zone,
      (res) => {
        toast.success("Zone addes successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        dispatch(addZone(res.data.data));
        navigate("/floor");
      },
      (err) => {
        for (const key in err.response.data.errors) {
          toast.error(err.response.data.errors[key][0], {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        setIsLoading(false);
      }
    );
  };

  const verifyForm = () => {
    return name.trim() !== "" && zoneType && !isNaN(area);
  };

  return (
    <div className={classes.AddZone}>
      <div className="flex justify-between">
        <div>
          <h1 className="text-3xl font-bold text-gray-800 mb-4">Add Zone</h1>
          <p className="text-gray-600 mb-6">Manage your zones and tables from this view</p>
        </div>
        <div className="flex space-x-4 mt-6">
          <button
            onClick={() => navigate("/floor")}
            className={classes.SelectionBtn}
          >
            Cancel
          </button>
          <button
            isDisabled={!verifyForm()}
            text="Proceed"
            onClick={onProceedHandler}
            className={classes.SelectionBtn}
          >
            Add Zone
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        <input
          type="text"
          placeholder="Enter zone name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="border p-4 rounded-md w-full"
        />
        <input
          type="text"
          placeholder="Area (Optional)"
          value={area}
          onChange={(e) => setArea(e.target.value)}
          className="border p-4 rounded-md w-full"
        />
      </div>

      <div className="mb-4">
        <h2 className="text-xl font-medium text-gray-800 mb-2">Seating</h2>
        <div className="flex space-x-4">
          <button
            className={`p-4 w-full text-center rounded-md ${zoneType === 'in-door' ? 'bg-black text-white' : 'bg-gray-300 text-gray-700'
              }`}
            onClick={() => setZoneType('in-door')}
          >
            Indoor
          </button>
          <button
            className={`p-4 w-full text-center rounded-md ${zoneType === 'out-door' ? 'bg-black text-white' : 'bg-gray-300 text-gray-700'
              }`}
            onClick={() => setZoneType('out-door')}
          >
            Outdoor
          </button>
        </div>
      </div>


    </div>
  );
};

export default AddZone;
