import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderTypes: [],
  partySize: {
    value: 1,
    skipped: false,
    isAllocated: false,
    updated: false,
    customer_name: null,
    customer_number: null,
  },
  table: {
    id: -1,
    skipped: false,
    isAllocated: false,
    updated: false,
  },
  isAddingService: false,
  isEditing: false,
};

const createOrderSlice = createSlice({
  name: "createOrder",
  initialState,
  reducers: {
    toggleOrderTypes(state, action) {
      const index = state.orderTypes.indexOf(action.payload);
      if (index < 0) state.orderTypes.push(action.payload);
      else state.orderTypes.splice(index, 1);
    },
    clearOrderTypes(state, action) {
      state.orderTypes = [];
    },
    setPartySize(state, action) {
      state.partySize.value = action.payload;
      state.partySize.isAllocated = true;
      state.partySize.skipped = false;
      state.partySize.updated = true;
    },
    setCustomerDetails(state, action) {
      state.partySize.customer_name = action.payload?.customer_name;
      state.partySize.customer_number = action.payload?.customer_number;
    },
    skipPartySize(state, action) {
      state.partySize.isAllocated = false;
      state.partySize.skipped = true;
      state.partySize.updated = true;
    },
    setSelectedTable(state, action) {
      state.table.id = action.payload?.id;
      state.table.name = action.payload?.table_number;
      state.table.isAllocated = true;
      state.table.skipped = false;
      state.table.updated = true;
    },
    skipTableAllocation(state, action) {
      state.table.skipped = true;
      state.table.updated = true;
    },
    selectOrderTypes(state, action) {
      state.orderTypes = action.payload;
    },
    toggleAddingService(state, action) {
      state.isAddingService = action.payload;
    },
    switchToCartEditing(state, action) {
      state.isEditing = action.payload;
    },
    clearOrder(state, action) {
      state.orderTypes = [];
      state.partySize = {
        value: 1,
        skipped: false,
        isAllocated: false,
        updated: false,
        customer_name: null,
        customer_number: null,
      };
      state.table = {
        id: -1,
        skipped: false,
        isAllocated: false,
        updated: false,
      };
      state.selectedTable = {
        id: null,
        tableNum: null,
      };
      state.isAddingService = false;
      state.isEditing = false;
    },
    unSelectSelectedTable(state, action) {
      state.table.id = action.payload;
      state.table.isAllocated = false;
      state.table.skipped = false;
      state.table.updated = true;
    },
    setCustomerCallingCode(state, action) {
      state.partySize.customer_calling_code =
        action.payload?.customer_calling_code;
    },
    unSkipPartySize(state) {
      state.partySize.isAllocated = false;
      state.partySize.skipped = false;
      state.partySize.updated = false;
    },
    skipPartySizeAndRemember(state) {
      state.partySize.isAllocated = false;
      state.partySize.isSkippedRemember = true;
      state.partySize.updated = true;
    },
    unSkipPartySizeAndRemember(state) {
      state.partySize.isAllocated = false;
      state.partySize.isSkippedRemember = false;
      state.partySize.updated = false;
    },
  },
});

export const {
  toggleOrderTypes,
  clearOrderTypes,
  setPartySize,
  skipPartySize,
  setSelectedTable,
  unSelectSelectedTable,
  skipTableAllocation,
  clearOrder,
  toggleAddingService,
  switchToCartEditing,
  setCustomerDetails,
  skipPartySizeAndRemember,
  unSkipPartySize,
  unSkipPartySizeAndRemember,
  setCustomerCallingCode,
} = createOrderSlice.actions;

export default createOrderSlice.reducer;
