import Icon from "components/Icon/Icon";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TableServices } from "services/TableServices";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Backdrop from "components/Backdrop/Backdrop";
import Loader from "components/Loader/Loader";
import { addTable } from "redux/table";
import { addSelectedZone } from "redux/zone";
import SingleZone from "./SingleZone";
import GlobalPadding from "components/Layout/globalContainer/GlobalPadding";
import classes from "./AddTable.module.scss";

const AddTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableNumber, setTableNumber] = useState("");
  const [seats, setSeats] = useState("");
  const [shape, setShape] = useState("round");
  const [activeZone, setActiveZone] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const { zones } = useSelector((state) => state.zone);
  const onProceedHandler = () => {
    if (!verifyForm()) return;
    setIsLoading(true);

    const table = {
      table_number: tableNumber,
      no_of_seats: Number(seats),
      shape,
      zone_id: activeZone?.id,
    };

    TableServices.createTable(
      table,
      (res) => {
        toast.success("Table added successfully!");
        dispatch(addTable(res.data.data));
        navigate("/floor");
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
        toast.error("Error adding table.");
      }
    );
  };
  const tableShapeChangeHandler = (e) => {
    setShape(e.target.value);
  };
  const handleChange = (e) => {
    const value = e.target.value;
    if (value > 0 && value <= 12) {
      setSeats(value);
    }
  };
  const verifyForm = () =>
    tableNumber.trim() &&
    seats > 0 &&
    seats <= 12 &&
    shape &&
    activeZone !== null;
  const showZone = [...zones].filter(x => x.name !== 'Merged Table Zone')
  return (
    <GlobalPadding>
      <div className="w-full h-screen">
        {isLoading && (
          <Backdrop type="dark">
            <Loader />
          </Backdrop>
        )}

        <div className={classes.AddTable}>
          <div className="flex justify-between">
            <div >
              <h1 className="text-3xl font-semibold mb-4">Add Table</h1>
              <p className="text-gray-500 mb-8">
                Manage your zones and tables from this view
              </p>
            </div>
            {/* Buttons */}
            <div className="flex justify-end gap-4 mt-8">
              <PrimaryButton
                onClicked={() => navigate("/floor")}
                text="Cancel"
                customClasses={classes.SelectionBtn}
              />
              <PrimaryButton
                onClicked={onProceedHandler}
                text="Add Table"
                isDisabled={!verifyForm()}
                customClasses={classes.SelectionBtn}
              />
            </div>
          </div>

          {/* Table Name */}
          <div className="flex justify-between w-full space-x-4">
            <input
              placeholder="Enter the name of table"
              value={tableNumber}
              onChange={(e) => setTableNumber(e.target.value)}
              max="7"
              className="w-full border p-4 rounded-md w-full"
            />

            {/* Seating Capacity */}
            <input
              type="number"
              placeholder="Seating capacity"
              value={seats}
              onChange={handleChange}
              className="w-full border p-4 rounded-md w-full"
            />
          </div>
          {/* Zone Selection */}
          <div className="relative mt-6">
            <div
              className="border rounded-md p-3 cursor-pointer flex justify-between items-center"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <span>
                {activeZone ? activeZone.name : "Select zone"}
              </span>
              <Icon name="chevron-down" />
            </div>
            {showDropdown && (
              <div className="absolute bg-white border border-gray-300 w-full mt-2 max-h-60 overflow-auto rounded-md shadow-lg z-50">
                {showZone.map((zone) => (
                  <SingleZone
                    key={zone.id}
                    {...zone}
                    active={zone.id === activeZone?.id}
                    onClick={() => {
                      setActiveZone(zone);
                      setShowDropdown(false);
                      dispatch(addSelectedZone(zone.id));
                    }}
                  />
                ))}
              </div>
            )}
          </div>

          {/* Table Shape */}
          <div className={classes.TableShapes}>
            <label className={classes.TableRadioButton}>
              <input
                defaultChecked
                onChange={tableShapeChangeHandler}
                type="radio"
                name="table-shape"
                value="round"
              />
              <span className={classes.TableRadioButtonIcon}></span>
              <span className={classes.TableRadioButtonShapeRounded}></span>
            </label>
            <label className={classes.TableRadioButton}>
              <input
                onChange={tableShapeChangeHandler}
                type="radio"
                name="table-shape"
                value="rectangle"
              />
              <span className={classes.TableRadioButtonIcon}></span>
              <span className={classes.TableRadioButtonShapeRectangle}></span>
            </label>
            <label className={classes.TableRadioButton}>
              <input
                onChange={tableShapeChangeHandler}
                type="radio"
                name="table-shape"
                value="square"
              />
              <span className={classes.TableRadioButtonIcon}></span>
              <span className={classes.TableRadioButtonShapeSquare}></span>
            </label>
            <label className={classes.TableRadioButton}>
              <input
                onChange={tableShapeChangeHandler}
                type="radio"
                name="table-shape"
                value="circle"
              />
              <span className={classes.TableRadioButtonIcon}></span>
              <span className={classes.TableRadioButtonShapeCircle}></span>
            </label>
          </div>


        </div>
        <ToastContainer />
      </div>
    </GlobalPadding>
  );
};

export default AddTable;
